// avatar.scss

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.avatar-lg {
    width: 45px;
    height: 45px;
}

.avatar-xl {
    width: 70px;
    height: 70px;
}
