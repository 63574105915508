// 
// pricing.scss
// 

.nav.pricing-tab {
  justify-content: center;
  display: table;
  margin: 0 auto;
  background-color: $gray-200;
  border: 1px solid darken($gray-200, 3%);
  padding: 6px;
  border-radius: 50rem;

  .nav-item {
    display: inline-block;

    .nav-link {
      border-radius: 50rem;
      color: $gray-800;
      padding: .5rem 1.5rem;

      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.pricing-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  box-shadow: $box-shadow-sm;
  border-radius: 4px;
  padding: 50px;
  position: relative;
  overflow: hidden;

  .pricing-badge {
    background-color: $danger;
    color: $white;
    font-size: 13px;
    padding: 2px 40px;
    text-transform: uppercase;
    position: absolute;
    top: 40px;
    right: -40px;
    transform: rotate(45deg);
    box-shadow: 0 2px 8px rgba($bg-overlay, 0.3);

    [data-lang="pl"] & {
      top: 50px;
      right: -50px;
    }
  }

  .btn {
    margin-top: auto;
  }
}