//
// menu.scss
//
.navbar-custom {
  padding: 20px 0px;
  padding-right: 0 !important;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;

  .navbar-nav {
    .nav-item {
      .nav-link {
        position: relative;
        line-height: 26px;
        color: $gray-600;
        font-size: $base-font;
        display: inline-block;
        transition: all 0.5s;
        background-color: transparent !important;
        padding: 6px 0;
        margin: 0 18px;
        font-weight: 500;
        font-family: $font-primary;

        &:hover, &.active {
          color: $primary;
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 10px;
            right: 10px;
            border-radius: 10px;
            border-bottom: 2px solid $primary;
          }
        }
      }

      &:last-child {
        .nav-link {
          margin-right: 0;

          .nav-btn {

          }
        }
      }

      .nav-btn {
        background-color: rgba($primary, 0.15);
        border-color: rgba($primary, 0.2);
        color: $primary;
        transition: all 0.3s ease-in-out;

        &:focus {
          box-shadow: none;
        }

        &:hover,
        &.active {
          background-color: $primary !important;
          color: $white !important;
        }
      }
    }

    .nav-link {
      padding-right: 0px;
      padding-left: 0px;
    }

    &-lang {
      flex-direction: row;
      gap: 10px;

      .nav-item {
        //margin: 0 5px;
      }
    }
  }
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: $dark;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}


.nav {
  .open {
    > a, > a:focus, > a:hover {
      background-color: transparent;
      border-color: $blue;
    }
  }
}


// navbar-light
.navbar {
  &.navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: rgba($white, 0.7);

          &:hover, &.active {
            color: $white;
          }

          &.active {
            &::after {
              border-bottom: 2px solid $white;
            }
          }
        }

        .nav-btn {
          background-color: rgba($white, 0.15);
          border-color: rgba($white, 0.2);
          color: $white;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            background-color: $white;
            color: $primary;
          }
        }
      }
    }

    .logo {
      .logo-dark {
        display: none;
      }

      .logo-light {
        display: block;
      }
    }
  }
}


/********** navbar sticky *********/

.nav-sticky {
  &.navbar-custom {
    margin-top: 0px;
    padding: 14px 0px;
    background-color: $white;
    box-shadow: 0 0 7px 0 rgba($black, 0.2);
    color: $dark !important;

    .navbar-nav {
      margin-top: 0px;

      .nav-item {
        .nav-link {
          color: $dark;

          &:hover, &.active {
            color: $primary !important;
          }

          &.active {
            &::after {
              border-bottom: 2px solid $primary;
            }
          }

        }

        .nav-btn {
          background-color: rgba($primary, 0.15);
          border-color: rgba($primary, 0.2);
          color: $primary;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }

    }
  }

  .logo {
    .logo-dark {
      display: block !important;
    }

    .logo-light {
      display: none !important;
    }
  }
}

/********* Responsive *********/

@media (min-width: 200px) and (max-width: 991px) {
  .navbar-custom, .navbar-light {
    margin-top: 0px;
    padding: 10px 0px !important;
    background-color: $white !important;
    box-shadow: 0 10px 33px rgba($dark, .1);
    color: $dark !important;

    .navbar-nav {
      margin-top: 0px;

      .nav-item {
        .nav-link {
          transition: all 0.4s;
          color: $dark !important;
          margin: 0px;

          &.active {
            color: $primary !important;
          }
        }

        .nav-btn {
          background-color: rgba($primary, 0.15) !important;
          border-color: rgba($primary, 0.2) !important;
          color: $primary !important;

          &:hover,
          &:focus {
            background-color: $primary !important;
            color: $white !important;
          }
        }

      }
    }

    > .container {
      width: 90%;
    }
  }

  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $dark;
  }
  .logo {
    .logo-light {
      display: none !important;
    }

    .logo-dark {
      display: block !important;
    }
  }
}
